<template>
  <div class="timetable">
    <h1 class="header-title">Расписание групп</h1>
    <yandex-map
      ref="map"
      class="ymap-main"
      ymap-class="ymap-body"
      :settings="settings"
      :coords="phoenix.coordsPhoenix"
      zoom="12"
      :controls="[]"
    >
      <ymap-marker
        :coords="phoenix.coordsPhoenix"
        :options="{
          balloonCloseButton: true,
          balloonMinWidth: 340,
          hideIconOnBalloonOpen: false,
          balloonOffset: [0, 0],
        }"
        marker-id="group"
        :icon="phoenix.markerPhoenix"
      >
        <BaloonCustom slot="balloon" :times="phoenix.timePhoenix" />
      </ymap-marker>
      <ymap-marker
        :coords="ligthHouse.coordlight"
        marker-id="group"
        :icon="ligthHouse.markerlight"
        :options="{
          balloonCloseButton: true,
          balloonMinWidth: 340,
          hideIconOnBalloonOpen: false,
          balloonOffset: [0, 0],
        }"
      >
        <BaloonCustom slot="balloon" :times="ligthHouse.timeLightHouse" />
      </ymap-marker>
      <ymap-marker
        :coords="desna.coord"
        marker-id="group"
        :icon="desna.marker"
        :options="{
          balloonCloseButton: true,
          balloonMinWidth: 340,
          hideIconOnBalloonOpen: false,
          balloonOffset: [0, 0],
        }"
      >
        <BaloonCustom slot="balloon" :times="desna.time" />
      </ymap-marker>
    </yandex-map>
    <div class="group-body">
      <img
        class="logo-group"
        src="../assets/img/logo-phoenix.png"
        alt='Собрания группы "Феникс"'
      />
      <TimeTemplate :data="phoenix.timePhoenix" :title="phoenix.title" />
      <div class="group-body__address">
        <find-template
          :address="phoenix.address"
          :images="phoenix.imgList"
          :coords="phoenix.coordsPhoenix"
          :marker="phoenix.markerPhoenix"
        />
      </div>
    </div>
    <div class="group-body">
      <img
        class="logo-group"
        src="../assets/img/lith-house.png"
        alt='Собрания группы "Маяк"'
      />
      <TimeTemplate
        :data="ligthHouse.timeLightHouse"
        :title="ligthHouse.title"
      />
      <div class="group-body__address">
        <!-- <how-to-find-us-light /> -->
        <find-template
          :address="ligthHouse.address"
          :images="ligthHouse.imgList"
          :coords="ligthHouse.coordlight"
          :marker="ligthHouse.markerlight"
        />
      </div>
    </div>
    <div class="group-body">
      <img
        class="logo-group"
        src="../assets/img/desna/logo.jpg"
        :alt='desna.title'
      />
      <TimeTemplate
        :data="desna.time"
        :title="desna.title"
      />
      <div class="group-body__address">
        <!-- <how-to-find-us-light /> -->
        <find-template
          :address="desna.address"
          :images="desna.imgList"
          :coords="desna.coord"
          :marker="desna.marker"
          :link="desna.linkTg"
        />
      </div>
    </div>
  </div>
</template>


<script>
import toggleMixin from "@/toggleMixin";
import ToggleIframe from "@/components/ToggleIframe";
import FindTemplate from "@/components/Timetable/FindTemplate.vue";
import HowToFindUsZoom from "@/components/Timetable/HowToFindUsZoom.vue";
import TimeTemplate from "../components/Timetable/TimeTemplate.vue";
import { yandexMap, ymapMarker, loadYmap } from "vue-yandex-maps";
import BaloonCustom from "@/components/Timetable/BaloonCustom.vue";

export default {
  components: {
    HowToFindUsZoom,
    FindTemplate,
    TimeTemplate,
    yandexMap,
    ymapMarker,
    BaloonCustom,
  },
  mixins: [toggleMixin],
  data() {
    return {
      settings: {
        apiKey: "4566896d-289c-4258-a09c-019cb859197e",
        lang: "ru_RU",
        coordorder: "latlong",
        enterprise: false,
        version: "2.1",
      },
      openLith: false,
      openPh: false,
      data: [],
      phoenix: {
        title: 'Собрания группы "Феникс"',
        address: "проспект Гагарина 10/2 (ГАМАЮН) 4 этаж офис 410",
        imgList: [
          {
            background: `url("${require("@/assets/imgPhoenix/1.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/imgPhoenix/2.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/imgPhoenix/3.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/imgPhoenix/4.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
        ],
        coordsPhoenix: [54.774867, 32.045727],
        markerPhoenix: {
          layout: "default#imageWithContent",
          imageHref: `${require("@/assets/img/logo-phoenix-map.png")}`,
          imageSize: [50, 50],
          imageOffset: [-15, -15],
        },
        timePhoenix: [
          {
            week: "Понедельник",
            timeStart: "19:00",
            timeEnd: "20:15",
            pause: false,
            isOpen: false,
          },
          {
            week: "Вторник",
            timeStart: "19:00",
            timeEnd: "20:15",
            pause: false,
            isOpen: false,
          },
          {
            week: "Среда",
            timeStart: "20:00",
            timeEnd: "21:00",
            pause: false,
            isOpen: false,
          },
          {
            week: "Чертверг",
            timeStart: "19:00",
            timeEnd: "20:15",
            pause: false,
            isOpen: false,
          },
          {
            week: "Пятница",
            timeStart: "19:00",
            timeEnd: "20:15",
            pause: false,
            isOpen: false,
          },
          {
            week: "Суббота",
            timeStart: "20:00",
            timeEnd: "21:00",
            pause: false,
            isOpen: 'Открытое собрание каждую субботу',
          },
          // {
          //   week: "Воскресенье",
          //   timeStart: "15:00",
          //   timeEnd: "16:30",
          //   pause: true,
          //   isOpen: 'Открытое собрание в последнее воскресенье месяца',
          // },
          {
            week: "Воскресенье",
            timeStart: "20:00",
            timeEnd: "21:00",
            pause: false,
            isOpen: false,
          },
        ],
      },
      ligthHouse: {
        title: 'Собрания группы "Маяк"',
        address: "ул.Кашена 1, 5-й этаж, офис 503 ( бизнесцентр К1 ).",
        imgList: [
          {
            background: `url("${require("@/assets/imgLightHouse/1.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/imgLightHouse/2.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/imgLightHouse/3.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/imgLightHouse/4.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/imgLightHouse/5.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/imgLightHouse/6.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/imgLightHouse/7.jpeg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
        ],
        coordlight: [54.794944, 32.046437],
        markerlight: {
          layout: "default#imageWithContent",
          imageHref: `${require("@/assets/img/lith-house.png")}`,
          imageSize: [50, 50],
          imageOffset: [-15, -15],
        },
        timeLightHouse: [
          {
            week: "Понедельник",
            timeStart: "19:00",
            timeEnd: "20:25",
            pause: true,
          },
          { week: "Среда", timeStart: "19:00", timeEnd: "20:15", pause: false },
          {
            week: "Пятница",
            timeStart: "19:00",
            timeEnd: "20:15",
            pause: false,
          },
          {
            week: "Суббота",
            timeStart: "15:00",
            timeEnd: "16:30",
            pause: true,
          },
          {
            week: "Воскресенье",
            timeStart: "18:00",
            timeEnd: "19:30",
            pause: true,
          },
        ],
      },
      morning: {
        title: 'Собрание группы "Утро"',
        address: "проспект Гагарина 10/2 (ГАМАЮН) 4 этаж офис 410",
        timeMorning: [
          {
            week: "Среда",
            timeStart: "8:00",
            timeEnd: "9:00",
            pause: false,
            isOpen: false,
          },
          {
            week: "Суббота",
            timeStart: "11:00",
            timeEnd: "12:00",
            pause: false,
            isOpen: false,
          },
        ],
      },
      desna: {
        title: 'ДесNA',
        address: "г. Десногорск, 6 мкр. пристройка к общ. 408. «Центр культуры и молодёжной политики» каб. 2",
        imgList: [
          {
            background: `url("${require("@/assets/img/desna/img1.jpg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/img/desna/img2.jpg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/img/desna/img3.jpg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
          {
            background: `url("${require("@/assets/img/desna/img4.jpg")}") center no-repeat`,
            backgroundSize: "contain",
            width: "100%",
            height: "100%",
          },
        ],
        coord: [54.15077436403736, 33.29907542590324],
        marker: {
          layout: "default#imageWithContent",
          imageHref: `${require("@/assets/img/desna/logo.jpg")}`,
          imageSize: [50, 50],
          imageOffset: [-15, -15],
        },
        time: [
          {
            week: "Вторник",
            timeStart: "18:00",
            timeEnd: "19:00",
            pause: false,
            isOpen: false,
          },
          // {
          //   week: "Пятница",
          //   timeStart: "18:00",
          //   timeEnd: "19:00",
          //   pause: false,
          //   isOpen: false,
          // },
        ],
        linkTg: 'https://t.me/+MshraSv_C683MWRi'
      },
      tableItemsZoom: [
        {
          week: "Понедельник",
          timeStart: "21:00",
          timeEnd: "22:15",
          pause: "Комната: 460-880-4963",
          pass: "Пароль: 061037",
        },
        {
          week: "Среда",
          timeStart: "21:00",
          timeEnd: "22:15",
          pause: "Комната: 460-880-4963",
          pass: "Пароль: 061037",
        },
        {
          week: "Пятница",
          timeStart: "21:00",
          timeEnd: "22:15",
          pause: "Комната: 460-880-4963",
          pass: "Пароль: 061037",
        },
      ],
    };
  },

  created() {
    document.title = "Расписание групп";
  },

  // computed: {
  //   balloonTemplatePhoenix() {
  //     return ` <div class='body_balloon'>
  //           <div class='body_balloon_inner'>
  //             ${this.renderRow}
  //           </div>
  //       </div>`;
  //   },
  // },

  methods: {
    openLithHouse() {
      this.openLith = !this.openLith;
    },
    openPhoenix() {
      this.openPh = !this.openPh;
    },
  },
  async mounted() {
    let yamp = null;
    await loadYmap(this.settings);
  },
};
</script>


<style lang="scss">
$main-color-blue: #1c7fe2;

.logo-group {
  object-fit: cover;
}

.ymap-main {
  margin-bottom: 40px;
}

.group-body {
  display: grid;
  grid-template-columns: minmax(auto, 350px) 1fr;
  column-gap: 24px;
  margin-bottom: 24px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 12px;
  }

  &__title {
    font-size: 36px;
    margin: 0;
    margin-bottom: 24px;

    @media screen and(max-width: 360px) {
      font-size: 28px;
    }
  }

  &__address {
    grid-column: 1 / 3;
    align-self: end;

    strong {
      color: $main-color-blue;
    }

    @media (max-width: 800px) {
      grid-column: auto;
    }

    .address-light,
    .address-phoenix,
    .how-zoom {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-flow: wrap;
    }

    .address-light,
    .address-phoenix {
      &__map {
        flex-basis: 100%;
        display: grid;
        grid-template-columns: 1fr;

        .yamp {
          div {
            height: 450px !important;
          }
        }

        button {
          max-width: fit-content;
          margin: 0 auto 12px;
        }

        iframe {
          width: 100%;
          min-height: 400px;
        }
      }
    }

    .how-zoom {
      button {
        width: 100%;
      }
    }
  }
}

.group-zoom {
  &__info {
    &--time-body {
      @media (max-width: 800px) {
        .time-row {
          &__week {
            grid-column: 1 / 3;
          }

          &__time {
            grid-column: 3 / 4;
          }

          &__break {
            grid-column: 1 / 4;
            margin: 12px auto 0;
          }
        }
      }
    }
  }
}

.time {
  @media (max-width: 800px) {
    &__text {
      &-first {
        display: none;
      }
    }
  }

  &-body {
    font-size: 18px;
    font-weight: normal;
  }

  &-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 12px 6px;
    font-size: inherit;
    font-weight: inherit;

    &:nth-child(odd) {
      background: rgba(28, 127, 226, 0.3);
      color: #000;
    }

    &__week,
    &__time,
    &__break {
      align-self: center;
    }

    &__break {
      display: flex;
      
      gap: 12px;
    }

    &__week{
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__icon{
      position: relative;
      cursor: pointer;

      &::before{
        content: none;
        position: absolute;
        background: #fff;
        top: 2.5rem;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 250px;
        min-height: 50px;
        border-radius: 10px;
        padding: 1rem;
        box-sizing: border-box;
        box-shadow: 0 0 5px 1px #000;
      }

      &:hover{
        &::before{
          content: attr(data-icon-title);
        }
      }
    }

    @media (max-width: 800px) {
      &__time {
        text-align: center;
      }
    }

    &__room {
      border: 2px solid #000;
      display: block;
      padding: 12px;
      border-radius: 5px;
      text-decoration-line: none;
      color: #000;

      p {
        margin: 0;
        text-decoration: inherit;
      }
    }
  }
}

.social{
  margin-top: auto;
  background: #1c7fe2;
  padding: 12px;
  line-height: 30px;
  width: fit-content;

  &__link{
    color: #fff;
    text-decoration: none;
  }
}
</style>